
/**
 * 文件描述: 采集器看板接口
 * @author 
 * @dTime  
 * @see    
 */
import feach from "../axios";
const APIS = {
  BoardList: "/taskCount/taskCountList",
  SetStatus: "/taskCount/insertResetStatus",
  GetDetails: "/taskCount/taskCountDetail",
  GetHeartBeat: "/serverGrouping/list",
  GetAcquisitionCount: "/crawler/count",
}
/**
 * @description 获取采集器看板接口
 * @param 
 */
export const getBoardList = params => {
  return feach.request({
    url: APIS.BoardList,
    method: "GET",
    params
  });
};
/**
 * @description 修改状态接口
 * @param 
 */
 export const setStatus = data => {
  return feach.request({
    url: APIS.SetStatus,
    method: "POST",
    data
  });
};
/**
 * @description 获取详情数据接口
 * @param 
 */
 export const getDetails = params => {
  return feach.request({
    url: APIS.GetDetails,
    method: "GET",
    params
  });
};
/**
 * @description 获取心跳看板接口
 * @param 
 */
 export const getHeartBeat = params => {
  return feach.request({
    url: APIS.GetHeartBeat,
    method: "GET",
    params
  });
};
/**
 * @description 获取采集计数接口
 * @param 
 */
 export const getAcquisitionCount = params => {
  return feach.request({
    url: APIS.GetAcquisitionCount,
    method: "GET",
    params
  });
};